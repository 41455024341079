<template>
  <div class="row">
    <div v-for="(item, index) in products" :key="item.id" :class="isMobile ? 'col-6': 'col-3'">
      <template v-if="index < 4">
        <div class="asahi-search-results-item cursor-pointer" :class="{'mt-3': isMobile}" @click="navigateToProductPage(item)">
          <div class="asahi-search-results-item-img" v-bind:style="{ backgroundImage: 'url(' + item.small_image.url + ')' }"></div>
          <p>
            <span v-html="item.name.replace(RegExp('(' + searchType + ')', 'gi'), '<b>$1</b>')"></span>
          </p>
          <p v-if="!isMobile">{{ $t('Your price with VAT') }} <b>{{ item.your_price_tax }}</b></p>
          <p v-else> <b>{{ item.your_price_tax }}</b> {{ $t('with VAT') }}</p>
        </div>
      </template>
    </div>
  </div>
  <div class="row justify-content-center asahi-tabs-container-cta">
    <div v-if='products.length <= 0' class='fw-bold d-flex justify-content-center mb-4'>{{$t('No Result Found')}}</div>
    <div class="col-auto" :class="{'mt-4': isMobile}">
      <router-link to="/catalog/products">
        <AsahiButton type="secondary" @click="navigateToProducts">{{ $t('All results in this section') }}</AsahiButton>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AsahiButton from '@/components/library/AsahiButton.vue'

export default {
  name: 'AsahiBsSearchProducts',
  emits: ['closeModal'],
  components: {
    AsahiButton
  },
  props: {
    searchType: {
      type: String
    }
  },
  computed: {
    ...mapState('catalog', ['products']),
    isMobile () {
      return window.innerWidth <= 768
    }
  },
  methods: {
    navigateToProducts () {
      localStorage.setItem('searchTypeProduct', this.searchType)
      this.$router.push('/catalog/products')
      this.$emit('closeModal')
    },
    navigateToProductPage (item) {
      this.$navigate(`/catalog/${item.sku}`)
      this.$emit('closeModal')
    }
  }
}
</script>

<style lang="scss" scoped>
.asahi-search-results-item p {
  text-align: left;
}
</style>
