
import { defineComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'
import { setI18nLanguage } from '@/i18n'
import { LanguagesType } from '@/types'
import { constants } from '@/constants'
import AsahiBsHeader from '@/components/library/AsahiBsHeader.vue'
import AsahiBsFooter from '@/components/library/AsahiBsFooter.vue'
import AsahiBsNav from '@/components/library/AsahiBsNav.vue'
import BreadcrumbLayout from '@/components/library/BreadcrumbLayout.vue'
import { RolePermissionDetails } from '@/store/modules/users/interfaces/IUser'
import { getValueByLabel } from '@/utils/permissions'
import { IPortalHardCodeLink } from '@/store/modules/links/interfaces/IPortalHardCodeLink'
import AsahiOrderInformation from '@/components/layout/AsahiOrderInformation.vue'
import { LinksActionType } from '@/store/modules/links/interfaces'

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    AsahiOrderInformation,
    AsahiBsHeader,
    AsahiBsFooter,
    AsahiBsNav,
    BreadcrumbLayout
  },
  data: () => ({
    activeLanguage: (localStorage.getItem('lang')) as LanguagesType,
    theme: constants.application.theme,
    allowedPages: ['cart', 'myDocuments', 'my-profile', 'saved'],
    dictionary: {
      en: {
        createAnOrder: 'CREATE AN ORDER',
        createAnOrderIt: 'Create An Order',
        cart: 'MY CART',
        otherLinks: 'Other links',
        legalClause: 'Legal clause',
        backUp: 'Back up',
        menu: 'Menu',
        tutorial: 'Tutorial',
        search: 'Search',
        home: 'Homepage',
        profile: 'My Profile',
        contracts: 'Contracts',
        orders: 'Orders',
        history: 'History',
        savedOrders: 'Saved Orders',
        suggestedCart: 'Suggested Cart',
        catalog: 'Catalogue',
        products: 'Product Overview',
        favorites: 'My Favorites',
        favoritesMenu: 'MY FAVORITES',
        reporting: 'Reporting',
        myDocuments: 'My documents',
        marketingMaterials: 'Marketing Materials',
        news: 'News and Articles',
        newsEvents: 'News and Events',
        aboutPeroni: 'About Peroni',
        support: 'Support',
        faq: 'FAQ',
        knowledgeBase: 'Knowledge Base',
        supportTickets: 'Support Tickets',
        eLearning: 'Academy',
        skELearning: 'Academy',
        pubMenu: 'Menu Creator',
        skPubMenu: 'Menu Creator',
        emptiesManagement: 'Empties Management',
        contacts: 'Contacts',
        about: 'About us',
        privacyPolicy: 'Privacy Policy',
        cookies: 'Cookies and personal data protection',
        brands: 'Brands',
        productCode: 'Product code',
        createNewUser: 'Create New User',
        trainingLearning: 'Training & Learning',
        Unit: 'Unit',
        languages: {
          cs: 'Česky',
          en: 'English',
          de: 'Deutsch',
          sk: 'Slovensky'
        },
        login: 'Login',
        notificationsEmpty: 'No notifications',
        termsOfUse: 'Terms of use',
        corporateInformation: 'Corporate Information',
        corporateGovernance: 'Corporate Governance',
        goToCart: 'Go To Cart',
        removeItem: 'Remove Item'
      },
      cs: {
        createAnOrder: 'Vytvořit objednávku',
        administration: 'Administrace',
        cart: 'Košík',
        home: 'Hlavní stránka',
        profile: 'Můj profil',
        contracts: 'Přehled smluv',
        orders: 'Objednávky',
        history: 'Historie objednávek',
        savedOrders: 'Uložené objednávky',
        suggestedCart: 'Návrh objednávky',
        catalog: 'Katalog',
        products: 'Přehled výrobků',
        favorites: 'Moje oblíbené',
        favoritesMenu: 'Moje oblíbené',
        reporting: 'Přehled odběrů',
        myDocuments: 'Moje dokumenty',
        marketingMaterials: 'Materiály pro Vás',
        news: 'Novinky a články',
        aboutPeroni: 'O Peroni',
        support: 'Podpora',
        faq: 'Otázky a odpovědi',
        knowledgeBase: 'O portálu',
        supportTickets: 'Kontaktujte nás',
        eLearning: 'Akademie První u zdroje',
        pubMenu: 'Prazdroj menu',
        otherLinks: 'Další odkazy',
        legalClause: 'Právní doložka',
        backUp: 'Zpět nahoru',
        menu: 'Menu',
        tutorial: 'Průvodce',
        search: 'Vyhledat',
        contacts: 'Kontakty',
        about: 'O nás',
        privacyPolicy: 'Ochrana soukromí',
        cookies: 'Cookies a ochrana osobních údajů',
        brands: 'Značky',
        productCode: 'kód produktu',
        createNewUser: 'Vytvořit nového uživatele',
        Unit: 'Balení',
        languages: {
          cs: 'Česky',
          en: 'English',
          de: 'Deutsch',
          sk: 'Slovensky'
        },
        login: 'Přihlásit se',
        notificationsEmpty: 'Žádné notifikace',
        termsOfUse: 'Podmínky použití',
        goToCart: 'Košík',
        removeItem: 'Odstranit'
      },
      sk: {
        createAnOrder: 'Vytvoriť objednávku',
        administration: 'Administrace',
        cart: 'Košík',
        home: 'Hlavná stránka',
        profile: 'Môj profil',
        contracts: 'Prehľad zmlúv',
        orders: 'Objednávky',
        history: 'História objednávok',
        savedOrders: 'Uložené objednávky',
        suggestedCart: 'Odporúčaný tovar',
        catalog: 'Katalóg',
        products: 'Prehľad výrobkov',
        favorites: 'Moje obľúbené',
        favoritesMenu: 'Moje obľúbené',
        reporting: 'Prehľad odberov',
        myDocuments: 'Moje dokumenty',
        marketingMaterials: 'Materiály pre Vás',
        news: 'Novinky a články',
        aboutPeroni: 'O Peroni',
        support: 'Podpora',
        faq: 'Otázky a odpovede',
        knowledgeBase: 'O portáli',
        supportTickets: 'Kontaktujte nás',
        eLearning: 'Akadémia Prvý pri zdroji',
        skELearning: 'Akadémia Prvý pri zdroji',
        pubMenu: 'Prazdroj menu',
        skPubMenu: 'Prazdroj menu',
        otherLinks: 'Ďalšie odkazy',
        legalClause: 'Právna doložka',
        backUp: 'Späť hore',
        menu: 'Menu',
        tutorial: 'Sprievodca',
        search: 'Hľadať',
        contacts: 'Kontakty',
        about: 'O nás',
        privacyPolicy: 'Ochrana súkromia',
        cookies: 'Cookies a ochrana osobných údajov',
        brands: 'Značky',
        productCode: 'kód produktu',
        createNewUser: 'Vytvoriť nového užívateľa',
        Unit: 'Balenie',
        languages: {
          cs: 'Česky',
          en: 'English',
          sk: 'Slovensky'
        },
        login: 'Prihlásiť sa',
        notificationsEmpty: 'Žiadne notifikácie',
        termsOfUse: 'Podmienky používania',
        goToCart: 'Košík',
        removeItem: 'Odstrániť'
      }
    }
  }),
  computed: {
    ...mapState('basket', ['id', 'items', 'progressBarMsg']),
    ...mapState('catalog', ['wishlist']),
    ...mapGetters('main', ['getPermissions']),
    ...mapGetters('basket', ['progressBarMessage', 'progressBarSuccess', 'showProgressBarMessage']),

    isMobile () {
      return window.innerWidth <= 768
    },
    rightMenu () {
      return [
        ...this.checkPermission('My Profile', {
          label: this.$t('My Profile'),
          href: '/my-profile/details',
          name: 'profile',
          isActive: false

        }),
        ...this.checkPermission('My Documents', {
          label: this.$t('My Documents'),
          href: '/my-documents',
          name: 'myDocuments',
          isActive: false,
          icon: 'newspaper'
        }),
        ...this.checkPermission('Reporting', {
          label: this.$t('Reporting'),
          href: '/reporting',
          name: 'reporting',
          isActive: false,
          icon: 'newspaper'
        }),
        ...this.checkPermission('Contracts', {
          label: this.$t('Contracts'),
          href: '/contracts',
          name: 'contracts',
          isActive: false,
          icon: 'file-contract'
        }),
        {
          label: this.$t('Log out'),
          name: 'Log Out'
        }
      ]
    },
    savedPath () {
      return this.$route.path.includes('saved')
    },
    customerType (): RolePermissionDetails | undefined {
      if (this.user && this.user?.permissionSchema?.length > 0) {
        return this.user?.permissionSchema[0]?.rpDetails?.find((item: RolePermissionDetails) => item.customerType)
      }
      return undefined
    },
    user () {
      return this.$store.state.auth.user
    },
    allPermission () {
      return this.user.allAvailablePermission
    },
    portalHardCodeLink (): IPortalHardCodeLink[] {
      return this.$store.state.links.portalHardCodeLink?.results ?? []
    },
    favoritesItems () {
      return this.wishlist?.items_v2?.shipto_item_count
    },
    languages () {
      if (this.user.country === 'CZE' || window.location.hostname.includes('.cz')) {
        return ['cs', 'en']
      }

      if (this.user.country === 'SVK' || window.location.hostname.includes('.sk')) {
        return ['sk', 'en']
      }
      return ['en', this.activeLanguage].filter((x, index, self) => x != null && self.indexOf(x) === index)
    },
    computedDictionary () {
      const links = this.linksStore?.links?.reduce((acc, value, index) => {
        acc['custom-link' + (index + 1)] = value?.title
        return acc
      }, {} as Record<string, string>)

      const activeLanguage = this.activeLanguage as keyof typeof this.dictionary
      return { ...this.dictionary, [activeLanguage]: { ...this.dictionary[activeLanguage], ...links } }
    },
    linksStore () {
      return this.$store.state.links
    },
    portalPageMenuLink () {
      return this.$store.state.links.portalPageMenuLink?.items ?? []
    },
    cartItems () {
      return this.items?.length
    },
    catalogPermission () {
      return this.checkPermission('Catalogue', {}).length !== 0
    },
    footerLinksBrands () {
      let brandLinks

      if (this.user.country === 'SVK') {
        brandLinks = this.portalHardCodeLink
          .filter((data) => data.type.toLowerCase() === 'mobile brands sk' || data.type.toLowerCase() === 'mobile brands')
          .map((data) => ({
            label: data.label,
            link: data.url,
            target: '_blank',
            order: data.order
          }))
          .sort((a, b) => a.order - b.order)
      } else {
        brandLinks = this.portalHardCodeLink
          .filter((data) => data.type.toLowerCase() === 'mobile brands cz' || data.type.toLowerCase() === 'mobile brands')
          .map((data) => ({
            label: data.label,
            link: data.url,
            target: '_blank',
            order: data.order
          }))
          .sort((a, b) => a.order - b.order)
      }
      return [
        {
          title: 'brands'
        },
        ...brandLinks
      ]
    },
    footerLinksCookies () {
      let dataName = 'cookies'
      if (this.user?.country === 'SVK' || window.location.hostname.includes('.sk')) {
        dataName = 'cookiesSK'
      }
      const aboutUsLinks = this.portalHardCodeLink
        .filter((data) => data.name === dataName)
        .map((data) => ({
          label: this.$t('Cookies'),
          href: data.url,
          target: '_blank'
        }))
      return aboutUsLinks
    },
    footerLinksTermsOfUse () {
      let dataName = 'termsOfUse'
      if (this.user?.country === 'SVK' || window.location.hostname.includes('.sk')) {
        dataName = 'termsOfUseSK'
      }
      const aboutUsLinks = this.portalHardCodeLink
        .filter((data) => data.name === dataName)
        .map((data) => ({
          label: this.$t('Terms of use'),
          href: data.url,
          target: '_blank'
        }))
      return aboutUsLinks
    },
    footerLinksPrivacyPolicy () {
      let dataName = 'privacyPolicy'
      if (this.user?.country === 'SVK' || window.location.hostname.includes('.sk')) {
        dataName = 'privacyPolicySK'
      }
      const aboutUsLinks = this.portalHardCodeLink
        .filter((data) => data.name === dataName)
        .map((data) => ({
          label: this.$t('Privacy policy'),
          href: data.url,
          target: '_blank'
        }))
      return aboutUsLinks
    },
    isOnOtherPortalPages () {
      return this.$route.name !== 'products' && this.$route.name !== 'homepage' && this.$route.name !== 'favorites'
    }
  },

  methods: {
    changeLanguage (langCode: LanguagesType) {
      this.activeLanguage = langCode
      setI18nLanguage(langCode)
    },
    checkPermission (permission: string, value: Record<string, any>, additionalPremmision = true) {
      if (this.customerType) localStorage.setItem('customertype', this.customerType?.customerType)

      return this.allPermission?.includes(permission) &&
      additionalPremmision &&
      getValueByLabel(this.getPermissions(), permission)
        ? [value]
        : []
    },
    getLinkByName (name: string) {
      const linkObj = this.portalHardCodeLink.find((link) => link.name === name)
      return linkObj ? { url: linkObj.url, label: linkObj.label } : { url: '', label: '' }
    },
    async removeProductFromCart (productId: string) {
      if (this.$store.state.basket?.id && productId) {
        await this.$store.dispatch('basket/REMOVE_ITEM', {
          cart_id: this.$store.state.basket.id,
          cart_item_id: productId
        }, { root: true })
        await this.$store.dispatch('basket/FETCH_MIN_CART_QUERY_FOR_CATALOG', { cart_id: this.$store.state.basket.id }, { root: true })
      }
    },
    fetchPortalPageMenuLink () {
      this.$store.dispatch(LinksActionType.FETCH_PAGE_MENU_LINK)
    }
  },
  mounted () {
    if (this.isOnOtherPortalPages) {
      this.$store.dispatch('catalog/FETCH_WISHLIST', { bypassloading: true }, { root: true })
    }
  },
  watch: {
    activeLanguage (newLang, oldLang) {
      if (newLang !== oldLang) {
        this.fetchPortalPageMenuLink()
      }
    }
  }
})
