<template>
  <div class="asahi-tooltip-container" :class="{'order-card-tooltip': orderCard, 'last-order': moveLeft}">
    <div :class="['triangle-up', isSupportTicket ? 'support-ticket' : '', {'last-order-triangle': moveLeft}]"></div>
    <div class="asahi-tooltip-content">
      <slot name="header"></slot>
      <div  class="asahi-tooltip-body">
        <slot name="body"></slot>
      </div>
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'AsahiToolTip',
  props: {
    orderCard: {
      type: Boolean,
      default: false
    },
    isSupportTicket: {
      type: Boolean,
      default: false
    },
    moveLeft: {
      type: Boolean,
      default: false
    }
  }
})
</script>

<style scoped lang='scss'>
.asahi-tooltip-container {
  top:1rem;
  position: absolute;
  width: inherit;
  background-color: $tool-tip-bg-color;
  z-index:10;
  border-radius:8px;
  padding:0.75rem 1rem;
  color: $tool-tip-text-color;
  height: auto;
}
.last-order {
  left: -90px;
}

.asahi-tooltip-container.order-card-tooltip{
  width: 225px;
}

.asahi-tooltip-content {
  min-width:100px;
}

.support-ticket {
  left: 82% !important
}

.asahi-tooltip-body {
  overflow-y: auto;
  max-height: 250px;
  min-width:100px;
  padding-right: 10px;
}

.asahi-tooltip-body::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

.asahi-tooltip-body::-webkit-scrollbar-track {
  background: $tool-tip-scroll-track; /* Color of the track */
}

.asahi-tooltip-body::-webkit-scrollbar-thumb {
  background: $tool-tip-text-color; /* Color of the thumb */
  border-radius: 4px; /* Rounded corners of the thumb */
}

.asahi-tooltip-body::-webkit-scrollbar-thumb:hover {
  background: $tool-tip-text-color; /* Color of the thumb on hover */
}

.triangle-up {
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 20px solid $tool-tip-arrow-color;
  z-index: 0;
}
.last-order-triangle {
  left: 90% !important;
}
</style>
