
import { defineComponent, ref, PropType, Ref, nextTick } from 'vue'
import {
  getUTCDate, getDeliveryDaysOnWeek, getHolidayDays
} from '@/utils/deliveryDate'
import { isEqual, getDay, format } from 'date-fns'

import { mapState } from 'vuex'
/**
 * use this to get documentation of vue-datepicker
 * https://vue3datepicker.com/props/calendar-configuration/
 */
import VueDatePicker from '@vuepic/vue-datepicker'
import type { DatePickerInstance } from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import AsahiTypography from '@/components/library/Typography.vue'

import ChevronLeftIcon from '@/assets/icons/library/chevron-left.svg'
import ChevronRightIcon from '@/assets/icons/library/chevron-right.svg'
import CalendarIcon from '@/assets/icons/calendar-white.svg'
import AsahiButton from '../library/AsahiButton.vue'

export default defineComponent({
  name: 'DeliveryDatePickerInner',
  components: {
    AsahiTypography,
    VueDatePicker,
    AsahiButton
  },
  props: {
    modelValue: {
      type: String,
      required: true
    },
    isCompact: {
      type: Boolean,
      default: false
    },
    shipmentId: {
      type: String,
      required: true
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    isPlaceholder: {
      type: Boolean,
      default: false
    }
  },

  emits: ['update:modelValue', 'datePickerOpen'],

  setup () {
    const vueDatePickerRef: Ref<DatePickerInstance> = ref(null)

    return { vueDatePickerRef }
  },
  data: () => ({
    deliveryDate: '' as string,
    firstAvailableDate: '' as string,
    changedShipmentId: false,
    ChevronLeftIcon,
    ChevronRightIcon,
    CalendarIcon,
    deliveryDateToSend: '' as string,
    markers: ref([
      {
        date: new Date(),
        type: 'dot',
        color: '#1A3E13'
      }
    ]),
    inputClassName: 'input-delivery-date',
    store: '',
    isLoading: false
  }),

  computed: {
    ...mapState('basket', [
      'availableDays',
      'availableDate'
    ]),
    ...mapState('main', ['holidaysConfig']),
    ...mapState('catalog', ['shipTos']),

    availableDates (): string[] {
      return this.getAvailableDates()
    },

    localeValue () {
      return this.$i18n.locale as string
    },
    holidays () {
      return getHolidayDays(this.holidaysConfig)
    },
    dayOfWeek () {
      return [this.$t('MON'), this.$t('TUE'), this.$t('WED'), this.$t('THU'), this.$t('FRI'), this.$t('SAT'), this.$t('SUN')]
    }
  },

  watch: {
    modelValue: {
      handler (value) {
        if (value != null && value !== '') {
          this.deliveryDate = value
        }
      },
      immediate: true
    },

    shipmentId: {
      async handler (value, oldValue) {
        this.changedShipmentId = oldValue ? value !== oldValue : false
        const shipToId = localStorage.getItem('shipToId')

        if (this.changedShipmentId && shipToId) {
          await this.$store.dispatch('basket/GET_DELIVERY_AVAILABLE_DATE', { shipmentId: this.shipmentId, shipTo: shipToId, store: this.store })
        }
      }
    },
    isLoading () {
      this.updateInputWrapClass()
    }
  },

  async mounted () {
    this.isLoading = true
    this.store = localStorage.getItem('store') ?? ''
    if (this.isCompact) {
      this.inputClassName = 'input-delivery-date-black'
    }
    const shipToId = localStorage.getItem('shipToId')
    if ((this.availableDays == null || this.availableDays?.length === 0) && shipToId) {
      await this.$store.dispatch('basket/GET_DELIVERY_AVAILABLE_DATE', { shipmentId: this.shipmentId, shipTo: shipToId, store: this.store })
    }
    this.isLoading = false
  },
  methods: {
    updateInputWrapClass () {
      const inputWrap = this.vueDatePickerRef?.$el.querySelector('.dp__input_wrap')
      if (inputWrap) {
        if (this.isLoading) {
          inputWrap.classList.add('full-width')
        } else {
          setTimeout(() => {
            inputWrap.classList.remove('full-width')
          }, 2000)
        }
      }
    },
    getAvailableDates () {
      if (this.availableDays?.length > 0) {
        this.firstAvailableDate = this.availableDays[0]
        const availableDates = Array.from(
          this.availableDays.map((d: string) => {
            const dat = new Date(d)

            const formatter = format(dat, 'yyyy-MM-dd')
            return formatter
          }),
          (availableDate:string) => {
            return getUTCDate(new Date(availableDate))
          })

        // console.log(this.modelValue, '-modelValue', this.modelValue, this.changedShipmentId)
        // if (this.modelValue == null || this.modelValue === '' || this.changedShipmentId) {
        //   this.setFirstAvailableDate(this.firstAvailableDate)
        //   this.changedShipmentId = false
        // }
        return availableDates
      }
      return []
    },

    setFirstAvailableDate (firstAvailableDate: string) {
      const firstAvailabledDate = new Date(firstAvailableDate)
      this.onDateSelection(getUTCDate(firstAvailabledDate), true)
    },

    onDateSelection (value: string, isFirstAvailable = false) {
      const deliveryDate = new Date(value)
      const dToSend = getUTCDate(deliveryDate)
      this.deliveryDateToSend = dToSend

      if (!this.isMobile) {
        this.$emit('update:modelValue', { value: this.deliveryDateToSend, isFirstAvailable })
      }
    },

    saveChanges () {
      this.vueDatePickerRef?.selectDate()

      if (this.deliveryDateToSend !== '') {
        this.$emit('update:modelValue', this.deliveryDateToSend)
      }
      this.vueDatePickerRef?.closeMenu()

      this.onCalendarClose()
    },

    getDayClass (date: Date, _internalDate: Date | Date[] | null) {
      const weekDay = getDay(date)
      const isWeekend = (weekDay % 6) === 0
      const dateStr = format(date, 'yyyy-MM-dd')
      const deliveryDateFormatted = format(new Date(this.deliveryDate), 'yyyy-MM-dd')
      const deliveryDaysOnWeek = getDeliveryDaysOnWeek(this.shipmentId, this.shipTos)
      const firstDateFormatted = format(new Date(this.firstAvailableDate), 'yyyy-MM-dd')
      if (deliveryDateFormatted === dateStr) {
        return 'active-cell'
      }
      if (this.availableDates.includes(dateStr) && deliveryDaysOnWeek.includes(weekDay)) {
        return 'delivery-cell'
      }
      if (dateStr >= firstDateFormatted && !isWeekend) {
        return 'working-cell'
      }
      return ''
    },
    onCalendarOpen () {
      const component = this.$refs.vueDatePickerRef as any
      const el = component.$el as HTMLElement
      el.querySelectorAll('.dp__input_wrap').forEach((el) => {
        el.classList.add('open')
      })
      this.$emit('datePickerOpen', true)
    },
    onCalendarClose () {
      const component = this.$refs.vueDatePickerRef as any
      const el = component.$el as HTMLElement
      el.querySelectorAll('.dp__input_wrap').forEach((el) => {
        el.classList.remove('open')
      })
      this.$emit('datePickerOpen', false)
    }
  }
})
