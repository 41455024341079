
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BasicModal',

  props: {
    closeModal: {
      type: Function,
      default: () => {
        /*  */
      }
    },
    title: String,
    subtitle: String,
    description: String,
    icon: String
  },
  emits: ['closeModal'],
  methods: {

  }
})

