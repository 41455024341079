
import { defineComponent, PropType } from 'vue'
import DeliveryDatePickerInner from './DeliveryDatePickerInner.vue'
import { IHolidaysConfig } from '@/store/modules/main/intefaces/IConfig'

export default defineComponent({
  name: 'DeliveryDatePicker',

  props: {
    modelValue: {
      type: String,
      required: true
    },
    isCompact: {
      type: Boolean,
      default: false
    },
    shipmentId: {
      type: String,
      required: true
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },

  emits: ['update:modelValue'],

  components: {
    DeliveryDatePickerInner
  },

  data: () => ({
    componentKey: 0,
    isDatePickerOpened: false
  }),

  methods: {
    forceRecalculate () {
      this.componentKey += 1
    },

    onDatePickerOpened (opened: boolean) {
      setTimeout(
        () => { this.isDatePickerOpened = opened }
        , 100)
    }
  }
})
