<template>
<div>
  <img src="../../assets/loaderV3.gif" alt="Loader spinner" :style="{'height': height + 'px', 'width': width + 'px'}">
</div>
</template>

<script>

export default {
  name: 'LoaderSpinner',
  props: {
    height: {
      type: Number,
      default: 150
    },
    width: {
      type: Number,
      default: 150
    }
  }
}
</script>

<style scoped>
</style>
